import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '163, 32, 42',
		'primary-dark': '95, 19, 25',
		'accent': '247, 198, 202',
		'accent-plus': '95, 19, 25',
	},
});
